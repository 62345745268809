import { EventEmitter as BaseEventEmitter } from 'events';

import {
  BackendEventsProcessor,
  BannerEmitter,
  CartEmitter,
  CheckoutEmitter,
  ConsentEmitter,
  EventEmitter,
  HistoryManager,
  ProductEmitter,
} from './emitters';

export * as bodl from './bodl/Bodl';

export { AddCartItemEvent, RemoveCartItemEvent } from './emitters/CartEmitter';
export { CheckoutBeginEvent, OrderPurchasedEvent } from './emitters/CheckoutEmitter';

const historyManager = new HistoryManager();
const baseEventEmitter = new BaseEventEmitter();
const eventEmitter = new EventEmitter(baseEventEmitter, historyManager);

export const cart = new CartEmitter(baseEventEmitter, historyManager);
export const checkout = new CheckoutEmitter(baseEventEmitter, historyManager);
export const product = new ProductEmitter(baseEventEmitter, historyManager);
export const banner = new BannerEmitter(baseEventEmitter, historyManager);
export const consent = new ConsentEmitter(baseEventEmitter, historyManager);

const backendEventsProcessor = new BackendEventsProcessor(eventEmitter);

void backendEventsProcessor.run();

export default {
  cart,
  checkout,
  product,
  banner,
  consent,
  version: '08c7a1028ed7976c75a0f5f90d70867bff9ed1eb',
};
